import { RowHeroBannerFragment } from '../RowHeroBanner/RowHeroBanner.gql'
import { RowHeroSliderBannerFragment } from './RowHeroSliderBanner.gql'
import { RowHeroBanner } from '../RowHeroBanner/RowHeroBanner'
import { useEffect, useState } from 'react'

export function RowHeroSliderBanner(
  props: RowHeroSliderBannerFragment) {
  const { rowHeroBanners } = props
  const [slide, setSlide] = useState<RowHeroBannerFragment>()

  useEffect(() => {
    if (!slide && rowHeroBanners) {
      setSlide(rowHeroBanners[Math.floor(Math.random() * rowHeroBanners.length)])
    }
  }, [slide, rowHeroBanners])

  return slide ? <RowHeroBanner {...slide} /> : <div style={{width: '100vw', height: '100vh', background:'#eaeaea'}} />
}
