import { RichText } from '@graphcommerce/graphcms-ui'
import {breakpointVal, HeroBanner} from '@graphcommerce/next-ui'
import {Box, Button} from '@mui/material'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

const textColor = '#FDCB00'

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { copy, heroAsset, pageLinks, heroVariant, fullScreen } = props
  const links = pageLinks.slice(0, 1).map(({ url, title }, index) => (
    <Button key={url} href={url} variant='text' size='large' color='inherit' sx={{
      width: '100%',
      height: '100%',
      borderRadius: '0',
      '& span': {
        display: 'none'
      }
    }}>
      <span>{title}</span>
    </Button>
  ))
  const minHeight = fullScreen === true ? { xs: `100vh`, md: `100vh` } : { xs: `min(70vh,600px)`, md: `min(70vh,1080px)` }
  const heroCopyStyles = {
    Center: (theme) => ({
      paddingLeft: 0,
      paddingRight: 0,
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& .HeroBanner-wrapper': {
        borderRadius: 0,
      },
      '& .HeroBanner-copy': {
        minHeight,
        color: textColor,
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacings.xl,
          justifyItems: 'center',
          alignContent: 'center',
          textAlign: 'center',
          width: '100%',
        },
      },
      '& .HeroBanner-copy em': {
        color: '#fff',
      },
      '& .HeroBanner-copy p': {
        marginBottom: '0'
      },
    }),
    BottomLeft: (theme) => ({
      paddingLeft: 0,
      paddingRight: 0,
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& .HeroBanner-wrapper': {
        borderRadius: 0,
      },
      '& .HeroBanner-copy': {
        minHeight,
        color: textColor,
        [theme.breakpoints.up('sm')]: {
          maxWidth: '50%',
          padding: theme.spacings.xl,
          justifyItems: 'start',
          alignContent: 'end',
          textAlign: 'left',
          width: '100%',
        },
      },
      '& .HeroBanner-copy em': {
        color: '#fff',
        fontStyle: 'normal'
      },
      '& .HeroBanner-copy p': {
        marginBottom: '0'
      },
    }),
    BottomRight: (theme) => ({
      paddingLeft: 0,
      paddingRight: 0,
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& .HeroBanner-wrapper': {
        borderRadius: 0,
      },
      '& .HeroBanner-copy': {
        minHeight,
        color: textColor,
        [theme.breakpoints.up('sm')]: {
          maxWidth: '50%',
          padding: theme.spacings.xl,
          justifyItems: 'end',
          alignContent: 'end',
          textAlign: 'right',
          width: '100%',
        },
      },
      '& .HeroBanner-copy em': {
        color: '#fff',
        fontStyle: 'normal'
      },
      '& .HeroBanner-copy p': {
        marginBottom: '0'
      },
    })
  }

  return (
    <HeroBanner
      pageLinks={[
        <Box key={Math.random()} sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '10'
        }}>{links}</Box>
      ]}
      videoSrc={heroAsset.url}
      sx={heroCopyStyles[heroVariant ?? 'Center']}
    >
      <RichText
        {...copy}
        sxRenderer={{
          paragraph:  (theme) => ({
            typography: 'overline',
            ...breakpointVal('fontSize', 12, 16, theme.breakpoints.values),
          }),
          'heading-one': (theme) => ({
            textTransform: 'unset',
            mt: 1,
            mb: theme.spacings.sm,
            ...breakpointVal('fontSize', 62, 102, theme.breakpoints.values),
            '& strong': {
              display: 'block'
            },
            '& u': {
              WebkitTextFillColor: textColor,
              WebkitTextStroke: `1.2px #000`,
              display: 'block'
            },
          }),
          'heading-two': (theme) => ({
            textTransform: 'unset',
            mt: 1,
            mb: theme.spacings.sm,
            ...breakpointVal('fontSize', 62, 102, theme.breakpoints.values),
            '& strong': {
              display: 'block'
            },
            '& u': {
              WebkitTextFillColor: textColor,
              WebkitTextStroke: `1.2px #000`,
              display: 'block'
            },
          }),
        }}
      />
    </HeroBanner>
  )
}
