import {Asset, AssetFragment, RichText} from '@graphcommerce/graphcms-ui'
import {Box, Button, Link, Typography} from '@mui/material'
import {responsiveVal} from '@graphcommerce/next-ui/Styles/responsiveVal'
import {RowSpecialBannerFragment} from '../RowSpecialBanner/RowSpecialBanner.gql'
type RowSpecialBannerProps = RowSpecialBannerFragment

export function RowSpecialBanner(props: RowSpecialBannerProps) {
  const {
    asset,
    topic,
    copy,
    pageLinks
  } = props

  const links = pageLinks.slice(0, 1).map(({ url, title }) => (
    <Button key={url} href={url} variant='text' size='large' color='inherit' sx={{
      width: '100%',
      height: '100%',
      borderRadius: '0',
      '& span': {
        display: 'none'
      }
    }}>
      <span>{title}</span>
    </Button>
  ))

  return (
      <Box className='SpecialBanner-wrapper'
           sx={(theme) => ({
             display: 'grid',
             overflow: 'hidden',
             isolation: 'isolate',
             position: 'relative'
           })}
      >
        <Box
          className='SpecialBanner-asset'
          sx={(theme) => ({
            width: responsiveVal(200, 900),
            height: 'auto',
            marginBottom: theme.spacings.md,
            gridArea: '1 / 1',
            position: 'relative',
            '& img': {
              width: responsiveVal(200, 900),
              height: 'auto',
              objectFit: 'cover',
            },
            [theme.breakpoints.up('lg')]: {
              width: responsiveVal(250, 900),
              height: 'auto',
              '& img': {
                width: responsiveVal(250, 900),
                height: 'auto',
                objectFit: 'cover',
              },
            },
            [theme.breakpoints.between('md', 'xl')]: {
              width: '100%',
              '& img': {
                width: '100%',
              },
            },
          })}
        >
          <Asset asset={asset as AssetFragment} sizes='50vw' />
        </Box>
        <Box className='SpecialBanner-copy'
             sx={(theme) => ({
               gridArea: '1 / 1',
               zIndex: 1,
               display: 'grid',
               justifyItems: 'center',
               alignContent: 'center',
               textAlign: 'center',
               p: theme.spacings.md,
             })}
        >
          {topic && (
            <Typography variant='overline' className='SpecialBanner-topic'>
              {topic}
            </Typography>
          )}
          <RichText
            {...copy}
            sxRenderer={{
              paragraph: {
                textAlign: 'center' as const,
              },
            }}
          />
          <Box className='SpecialBanner-links'
               sx={{
                  typography: { xs: 'body2', md: 'h4' },
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  zIndex: '10'
                }}
          >
            {links}
          </Box>
        </Box>
      </Box>
  )
}
