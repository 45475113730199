import {Asset, RichText} from '@graphcommerce/graphcms-ui'
import { Box, Link, Typography } from '@mui/material'
import { RowSpecialBanner } from './RowSpecialBanner'
import { breakpointVal } from '@graphcommerce/next-ui'
import { RowSpecialBannerFragment } from '../RowSpecialBanner/RowSpecialBanner.gql'
import { RowSpecialBannerTwoFragment } from './RowSpecialBannerTwo.gql'

export function RowSpecialBannerTwo(props: RowSpecialBannerTwoFragment) {
  const {
    rowSpecialBannerFirst: firstBanner,
    rowSpecialBannerTwo: secondBanner
  } = props

  return (
    <Box
        className='SpecialBanner-row'
        sx={(theme) => ({
          padding: 0,
          display: 'grid',
          gridTemplateColumns: 'auto',
          marginBottom: theme.spacings.xl,
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '50% 50%',
          },
          [theme.breakpoints.up('lg')]: {
            maxWidth: theme.breakpoints.values.lg,
            marginLeft: 'auto',
            marginRight: 'auto'
          },
          '& .SpecialBanner-wrapper': {
            aspectRatio: '1/1'
          },
          '& .SpecialBanner-copy': {
            minHeight: { xs: 'min(50vh,600px)', md: 'min(50vh,1080px)' },
            color: theme.palette.primary.main,
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacings.xl,
              justifyItems: 'center',
              alignContent: 'center',
              textAlign: 'center',
              width: '100%',
            },
            '& h2': {
              ...breakpointVal('fontSize', 48, 82, theme.breakpoints.values),
            }
          },
          '& .SpecialBanner-asset': {
            minHeight: '100%',
            width: '100%',
            aspectRatio: '1/1',
            [theme.breakpoints.up('sm')]: {
              width: '100%',
              height: 'auto',
            }
          },
          '& .SpecialBanner-asset img': {
            minHeight: '100%',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '100%',
              height: 'auto',
            }
          }
        })}
      >
      <RowSpecialBanner {...firstBanner as RowSpecialBannerFragment} />
      <RowSpecialBanner {...secondBanner as RowSpecialBannerFragment} />
    </Box>
  )
}
